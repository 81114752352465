import React from 'react';

export default function useResize(width, height) {
  // create a state for storing dom dimensions
  const [dims, setDims] = React.useState([0, 0]);

  console.log(width, height);

  // create a callback that updates the state
  React.useEffect(() => {
    const callback = () => {
      if (document) {
        setDims([document.body.offsetWidth, document.body.offsetHeight]);
      }
    };
    callback();
    window.addEventListener('resize', callback);
    window.addEventListener('load', callback);
    return () => {
      window.removeEventListener('resize', callback);
      window.removeEventListener('load', callback);
    };
  }, [setDims]);

  // create the css style for the resize
  const scale = Math.min(dims[0] / width, dims[1] / height);
  const x = (dims[0] - width * scale) / 2;
  const y = (dims[1] - height * scale) / 2;
  const style = {
    width: `${width}px`,
    height: `${height}px`,
    transform: `translate(${x}px, ${y}px) scale(${scale})`,
    transformOrigin: '0 0',
  };

  // return the ref and the style
  return style
}
