import React from 'react';

const keys = {
  left: 37,
  right: 39,
  h: 72,
  j: 74,
  k: 75,
  l: 76,
};

const inputElements = ['input', 'select', 'textarea', 'a', 'button'];

export default function useKeyboard(callbacks) {
  // bind listeners to keyboard events
  React.useEffect(() => {
    // our keydown listener
    const handleKeyDown = e => {
      // return if we are holding META+... or CTRL+...
      if (e.metaKey || e.ctrlKey) return;

      // ignore when elements are focused
      const el = document.activeElement.tagName.toLowerCase();
      if (inputElements.includes(el)) return;

      // map keys to effects
      if ([keys.left, keys.h, keys.k].includes(e.keyCode)) {
        if (callbacks.previous) callbacks.previous();
      }
      if ([keys.right, keys.l, keys.j].includes(e.keyCode)) {
        if (callbacks.next) callbacks.next();
      }
    }

    // add to event listeners
    window.addEventListener('keydown', handleKeyDown)

    // remove callback
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [callbacks]);
}
