import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import useDeck from '../hooks/use-deck';
import Deck from '../components/deck';
import splitSlides from '../split-slides';

// this will perform a transformation of the mdx content
const wrapper = props => {
  const slides = splitSlides(props)
  return <Deck {...props} slides={slides} />
};

// this will return the index from the path
const getIndex = tail => {
  const n = Number(tail);
  return isNaN(n) ? 0 : n;
};

// our template
export default function (props) {
  // parse the data
  const { data, location, pageContext } = props;
  const { pathname } = location;
  const { slug } = pageContext;
  const tail = pathname.slice(slug.length + 1);

  // set the print state
  const print = tail === 'print' || tail === 'print/';
  const setPrint = useDeck(state => state.setPrint);
  setPrint(print);

  // get the index
  const index = getIndex(tail);

  return (
    <MDXRenderer {...props} index={ index } components={{ wrapper }}>
      { data.deck.body }
    </MDXRenderer>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    deck: deck(id: { eq: $id }) {
      body
      width
      height
    }
  }
`;
