import React from 'react'
import { Helmet } from 'react-helmet';
import { Router } from '@reach/router'
import shallow from 'zustand/shallow';

import Slide from './slide';
import Print from './print';
import useDeck from '../hooks/use-deck';
import useNavigation from '../hooks/use-navigation';
import useKeyboard from '../hooks/use-keyboard';

export default function Deck({ slides = [], pageContext, index }) {
  // parse the props
  const { slug, title, width, height } = pageContext;
  const length = slides.length;

  // update the slug and index data on change
  const [setSlug, setIndex, setLength, setWidth, setHeight] = useDeck(
    state => [
      state.setSlug, 
      state.setIndex, 
      state.setLength,
      state.setWidth,
      state.setHeight,
    ],
    shallow,
  );
  React.useEffect(() => setSlug(slug), [slug]); // eslint-disable-line
  React.useEffect(() => setIndex(index), [index]); // eslint-disable-line
  React.useEffect(() => setLength(length), [length]); // eslint-disable-line
  React.useEffect(() => setWidth(width), [width]); // eslint-disable-line
  React.useEffect(() => setHeight(height), [height]); // eslint-disable-line


  // add the keyboard listeners
  const [previous, next] = useNavigation();
  useKeyboard({ previous, next });

  // render the deck
  return (
    <>
      <Helmet>{title && <title>{title}</title>}</Helmet>
      <Router
        basepath={slug}
        style={{ height: '100%' }}>
        <Slide index={0} path="/" slide={slides[0]} width={ width } height={ height }/>
        {slides.map((slide, i) => (
          <Slide key={i} index={i} path={i + '/*'} slide={slide} width={ width }  height={ height }/>
        ))}
        <Print path="print/*" slides={ slides } />
      </Router>
    </>
  );
}
