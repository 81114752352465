import { useSwipeable } from 'react-swipeable'

import useNavigation from './use-navigation';

export default function useSwipe() {
  // get callbacks provided by the navigation hook
  const [previous, next] = useNavigation();

  // precompose our store callbacks into event callbacks
  const props = useSwipeable({ onSwipedLeft: next, onSwipedRight: previous });

  // return the event callbacks
  return props;
}
