/* eslint-disable */
import React from 'react'
import fp from 'lodash/fp';

import useDeck from './hooks/use-deck';

// value: as though we insert the new number, sorted
function withNumber(array, value) {
  const n = Number(value);
  if (Number.isFinite(n)) {
    const index = array.findIndex(m => m > n)
    return (
      index === -1
      ? (
        (array.length && array[array.length - 1] === value)
        ? array
        : [ ...array, n ]
      )
      : (
        (index && array[index - 1] === value)
        ? array
        : [ ...array.slice(0, index), n, ...array.slice(index) ]
      )
    );
  } else {
    return array.length ? [ ...array, Math.max(...array) + 1 ] : [0];
  }
}

// first pass: build the slides and fragment arrays
function buildArrays(children) {
  function reducer(fragmentIds, child) {
    const props = child.props || {};
    const newIds = (
      props.mdxType === 'Fragment'
      ? withNumber(fragmentIds, props.index)
      : fragmentIds
    );
    return (
      React.Children.toArray(props.children).reduce(reducer, newIds)
    );
  }
  return children.reduce(({ slides, fragmentIds }, child) => {
    const isSeparator = child.props.mdxType === 'hr';
    const newSlides = (
      isSeparator
      ? [ ...slides, []]
      : [ ...slides.slice(0, -1), [ ...slides[slides.length - 1], child ] ]
    );
    const newFragmentIds = (
      isSeparator
      ? [ ...fragmentIds, [] ]
      : [ 
        ...fragmentIds.slice(0, -1), 
        reducer(fragmentIds[fragmentIds.length - 1], child)
      ]
    );
    return { slides: newSlides, fragmentIds: newFragmentIds };
  }, { slides: [[]], fragmentIds: [[]] });
}

// second pass: update the fragments
function updateFragments(child, fragmentIds, index) {
  // if the child has no props, don't update it or the running index
  if (!child.props) return { child, index };

  // parse the props of the child
  const isFragment = child.props.mdxType === 'Fragment';
  const n = Number(child.props.index);

  // update the "index" field if we are dealing with a fragment
  const newChild = (
    isFragment
    ? (
      Number.isFinite(n)
      ? fp.set('props.index')(n)(child)
      : fp.set('props.index')(fragmentIds[index])(child)
    )
    : child
  );

  // update the running index
  const newIndex = (
    isFragment
    ? (
      Number.isFinite(n) 
      ? Math.max(fragmentIds.findIndex(m => m === n) + 1, index)
      : index + 1
    )
    : index
  );
  const children = newChild.props.children;
  if (!children) {
    return { child: newChild, index: newIndex };
  } else if (!Array.isArray(children)) {
    const obj = updateFragments(children, fragmentIds, newIndex);
    return {
      child: fp.set('props.children')(obj.child)(newChild),
      index: obj.index,
    };
  } else {
    const obj = children.reduce(({ array, idx }, c) => { 
      const obj = updateFragments(c, fragmentIds, idx);
      return { array: [...array, obj.child ], idx: obj.index };
    }, { array: [], idx: newIndex });
    return {
      child: fp.set('props.children')(obj.array)(newChild),
      index: obj.idx
    };
  }
}

export default ({ children, ...props }) => {
  // first pass: group the children by slides and build the fragment indices
  const { slides, fragmentIds } = React.useMemo(() => (
    buildArrays(React.Children.toArray(children))
  ), []);

  // populate the state with the fragment indices
  const setFragments = useDeck(state => state.setFragments);
  React.useEffect(() => setFragments(fragmentIds), []);

  // section pass: update the fragment indices
  return React.useMemo(() => (
    slides.map((slideChildren, i) => {
      if (!fragmentIds[i].length) return slideChildren;
      const obj = slideChildren.reduce(({ array, idx }, c) => {
        const { child, index } = updateFragments(c, fragmentIds[i], idx);
        return {
          array: [...array, child],
          idx: index,
        };
      }, { array: [], idx: 0 });
      return obj.array;
    })
  ), []);
}
