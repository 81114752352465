import shallow from 'zustand/shallow';
import fp from 'lodash/fp';
import { navigate } from '@reach/router'

import useDeck from './use-deck';

export default function useNavigation() {
  // get our store reducers and step data
  const [
    slug, 
    index, 
    setIndex, 
    length, 
    step, 
    setStep, 
    steps, 
    pSteps,
    print,
  ] = useDeck(
    state => [
      state.slug,
      state.index,
      state.setIndex,
      state.length,
      state.step,
      state.setStep,
      fp.get(`fragments.${state.index}.length`)(state),
      fp.get(`fragments.${state.index - 1}.length`)(state),
      state.print,
    ], 
    shallow
  );

  // create the callbacks
  const previous = () => {
    if (print) return;
    if (!steps || step === 0) {
      // if we are out of steps, go to previous slide
      const n = index - 1;
      if (n < 0) return;
      navigate([slug, n].join('/'));
      setStep(pSteps || 0);
      setIndex(n);
    } else {
      // otherwise, just update the step
      setStep(step - 1);
    }
  };
  const next = () => {
    if (print) return;
    if (!steps || step >= steps) {
      // if we are out of steps, extend to the next slide
      const n = index + 1;
      if (n >= length) return;
      navigate([slug, n].join('/'));
      setStep(0);
      setIndex(n);
    } else {
      // otherwise, just update the step
      setStep(step + 1);
    }
  };

  // return the callbacks
  return [previous, next];
}
