import React from 'react';

import Slide from './slide';

export default function Print({ slides }) {
  return (
    <>
    { 
      slides.map((slide, i) => (
        <Slide key={ i } index={ i } slide={ slide } />
      )) 
    }
    </>
  );
}
