import React from 'react';

import { root } from './index.module.css';
import useDeck from '../../hooks/use-deck';
import useSwipe from '../../hooks/use-swipe';
import useResize from '../../hooks/use-resize';
import { RefTeX } from '../tex';

export default function Slide({ slide, index, width, height, ...props }) {
  // get the print state
  const print = useDeck(state => state.print);

  // resize hook makes the presentation look the same in every window
  const sizeStyle = useResize(width || 960, height || 700);

  // swipe hooks allow gestures for slide changes
  const swipeProps = useSwipe()

  return (
    <RefTeX>
      <div 
        style={ print ? {} : sizeStyle } 
        className={ root } 
        { ...swipeProps }>
        { slide }
      </div>
    </RefTeX>
  );
}
